require("./resources/js/jquery-global");
require("bootstrap");
import styles from "./resources/scss/main.scss";
require("/resources/js/app.js");

import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.css";
require("owl.carousel");
// require("./resources/js/masonry");
require("@fancyapps/fancybox");
import "@fancyapps/fancybox/dist/jquery.fancybox.css";
